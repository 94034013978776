import React, { useState } from 'react';

import { Button, useValidator } from 'components';
import { AsyncSelect } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { PriceListsApi } from 'src/api';

import style from './AssignClient.module.scss';

const AssignClient = ({ onSubmit, priceListId, wholesalerId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const validator = useValidator();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      fpl_id: priceListId,
      supplier_id: selectedClient.value
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await PriceListsApi.assignClient(payload);
      notifyCommon([`Klient ${selectedClient.name} został przypisany do cennika`]);
      onSubmit && onSubmit();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <div className={style.inputs}>
        <p>Wybierz klienta, którego chcesz przypisać do cennika</p>

        <AsyncSelect
          apiCallback={PriceListsApi.getClientsForWholesaler}
          onChange={setSelectedClient}
          value={selectedClient}
          placeholder={'Wybierz klienta'}
          queryParams={{ wholesaler_id: wholesalerId }}
          id={'select-client'}
          validator={validator}
          rule={'required'}
          label={'Klient'}
          labelFormat={(client) => `${client.supplier_name} (${client.company_name})`}
          valueKey={'supplier_id'}
          isSearchable
        />
      </div>
      <Button
        className={style.button}
        label={'Przypisz do cennika'}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default AssignClient;
