import React from 'react';
import classNames from 'classnames';

import { Accordion, Card, SaveButton, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { PriceListsApi } from 'src/api';

import style from './AssignedClients.module.scss';
import shared from 'styles/Shared.module.scss';

const AssignedClients = ({ priceListId, clients, refreshData }) => {
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const handleDelete = async (client) => {
    const payload = {
      fpl_id: priceListId,
      supplier_id: client.supplier_id,
      company_id: client.company_id
    };

    try {
      await PriceListsApi.deleteClient(payload);
      notifyCommon(['Dostawca oraz klient zostali usunięci z tego cennika']);
      refreshData && refreshData();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const openDeleteModal = (client) => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz usunąć dostawcę ${client.supplier_name} (${client.company_name})?`,
      handleConfirm: () => handleDelete(client)
    });
  };

  return (
    <Accordion
      title={'Przypisani klienci'}
      extraDeps={[clients]}
    >
      {clients.length === 0 ? (
        <p className={style.emptyState}>Nie przypisano</p>
      ) : (
        <div className={style.table}>
          <div className={classNames(shared.tableHeader, style.header)}>
            <p>Dostawca</p>
            <p>Email dostawcy</p>
            <p>Nazwa kliniki</p>
            <p></p>
          </div>
          <div className={shared.tableCategory}>
            {clients.map((client, i) => (
              <div
                className={style.item}
                key={`clients-${client.company_id}-${i}`}
              >
                <span>{client.supplier_name}</span>
                <span>{client.supplier_email}</span>
                <span>{client.company_name}</span>

                <SaveButton
                  className={style.delete}
                  type={'delete'}
                  onClick={() => openDeleteModal(client)}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {renderedModalConfirm}
    </Accordion>
  );
};

export default AssignedClients;
