import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useIsUserAuth } from 'src/components';
import { refreshUserData } from 'src/features/onboarding/actions';

const DataRefresher = () => {
  const dispatch = useDispatch();
  const isUserAuth = useIsUserAuth();

  useEffect(() => {
    if (!isUserAuth) return;

    const userDataInterval = setInterval(() => dispatch(refreshUserData()), 10 * 60 * 1000);

    return () => {
      clearInterval(userDataInterval);
    };
  }, [isUserAuth]);

  return null;
};

export default DataRefresher;
