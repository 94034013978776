import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Menu, Modal, Tag, useIsMobile, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { PriceListsApi } from 'src/api';

import AssignClient from '../../../PriceListView/components/AssignClient';
import EditPriceList from '../../../PriceListView/components/EditPriceList';
import { changePriceListsVersion } from '../../actions';

import style from './PriceListThumbnail.module.scss';

const PriceListThumbnail = ({ data }) => {
  const { id, name, wholesaler_name, wholesaler_id, clients = [] } = data;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAssignClientModalVisible, setIsAssignClientModalVisible] = useState(false);

  const handleDelete = async () => {
    try {
      await PriceListsApi.deleteList(id);
      notifyCommon(['Cennik został usunięty']);
      dispatch(changePriceListsVersion());
    } catch (err) {
      notifyApiError(err);
    }
  };
  const actions = [
    {
      title: 'Zmień nazwę',
      icon: 'design',
      action: () => setIsEditModalVisible(true)
    },
    {
      title: 'Przypisz do klienta',
      icon: 'profile',
      action: () => setIsAssignClientModalVisible(true)
    },
    {
      title: 'Usuń',
      icon: 'trash',
      color: 'red',
      action: () =>
        handleOpenModalConfirm({
          message: `Czy na pewno chcesz usunąć cennik ${data.name}?`,
          handleConfirm: handleDelete
        })
    }
  ];

  const priceListName = (
    <Link
      to={`/price-lists/${id}`}
      className={style.linkWrapper}
    >
      <p className={style.name}>
        {name} ({wholesaler_name})
      </p>
    </Link>
  );

  return (
    <div className={style.thumbnail}>
      <div className={style.nameRow}>
        {priceListName}

        <div className={style.menuWrapper}>
          <Menu
            className={style.menu}
            actions={actions}
          />
        </div>
      </div>

      <div className={style.clients}>
        {clients?.length === 0 && (
          <Tag
            className={style.tag}
            value={'Nie przypisano'}
            color={'darkGray'}
          />
        )}
        {clients?.map((client, i) => (
          <Tag
            key={`client-${i}`}
            className={style.tag}
            value={`${client.supplier_name} (${client.company_name})`}
          />
        ))}
      </div>

      {/* Modals */}
      {renderedModalConfirm}
      <Modal
        visible={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
        title={'Edytuj cennik'}
      >
        <EditPriceList
          closeModal={() => setIsEditModalVisible(false)}
          priceListId={id}
          data={data}
        />
      </Modal>
      <Modal
        visible={isAssignClientModalVisible}
        onClose={() => setIsAssignClientModalVisible(false)}
        title={'Przypisz klienta'}
      >
        <AssignClient
          priceListId={+id}
          wholesalerId={wholesaler_id}
          onSubmit={() => {
            setIsAssignClientModalVisible(false);
            dispatch(changePriceListsVersion());
          }}
        />
      </Modal>
    </div>
  );
};

export default PriceListThumbnail;
