import React, { useState } from 'react';
import classNames from 'classnames';

import { Menu, Modal, ProductThumbnailImage, useIsMobile, useModalConfirm } from 'components';
import ChangePrice from 'components/layout/changeMenu/ChangePrice';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { PriceListsApi } from 'src/api';

import ProductPriceHistory from '../ProductPriceHistory';

import style from './ProductPriceThumbnail.module.scss';

const ProductPriceThumbnail = ({ productPrice, priceListId, refreshData }) => {
  const { thumbnail, image, product_name, product_id, producer_name } = productPrice;

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [price, setPrice] = useState(productPrice.price || 0);
  const [isPriceActive, setIsPriceActive] = useState(false);
  const [isPriceHistoryModalOpen, setIsPriceHistoryModalOpen] = useState(false);

  const isMobile = useIsMobile(768);

  const onPriceChange = async (newPrice) => {
    const payload = {
      f_price_id: productPrice.id,
      fpl_id: priceListId,
      product_id: product_id,
      price: newPrice
    };

    try {
      await PriceListsApi.addOrUpdatePrice(payload);
      notifyCommon(['Cena została zaktualizowana']);
      setPrice(newPrice);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleDelete = async () => {
    try {
      await PriceListsApi.deletePrice(productPrice.id);
      notifyCommon(['Usunięto cenę produktu z cennika']);
      refreshData();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const actions = [
    {
      title: 'Pokaż historię cen',
      icon: 'clock',
      action: () => setIsPriceHistoryModalOpen(true)
    },
    {
      title: 'Usuń',
      icon: 'trash',
      color: 'red',
      action: () =>
        handleOpenModalConfirm({
          message: `Czy na pewno chcesz usunąć produkt ${product_name} z cennika?`,
          handleConfirm: handleDelete
        })
    }
  ];

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <ProductThumbnailImage
          className={style.image}
          thumbnail={thumbnail}
          image={image}
          title={product_name}
          id={product_id}
        />
        <p className={classNames(style.text, style.trim)}>{product_name}</p>
      </div>

      <div className={classNames(style.wrapper, style.producer)}>
        {isMobile && <p className={style.label}>Producent: </p>}
        <p className={style.text}>{producer_name}</p>
      </div>

      <div className={style.wrapper}>
        {isMobile && <p className={style.label}>Cena za szt.: </p>}
        <ChangePrice
          price={price}
          setPrice={onPriceChange}
          isActive={isPriceActive}
          setIsActive={setIsPriceActive}
          productId={product_id}
          displayOver
        />
      </div>

      <div className={style.menuWrapper}>
        <Menu
          className={style.menu}
          actions={actions}
        />
      </div>

      {renderedModalConfirm}
      <Modal
        title={'Historia cen produktu'}
        visible={isPriceHistoryModalOpen}
        onClose={() => setIsPriceHistoryModalOpen(false)}
      >
        <ProductPriceHistory
          product={productPrice}
          priceListId={priceListId}
          productId={product_id}
        />
      </Modal>
    </div>
  );
};

export default ProductPriceThumbnail;
