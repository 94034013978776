import * as types from './types';

export const setMaintenance = (data) => (dispatch) => {
  dispatch({
    type: types.SET_MAINTENANCE,
    payload: {
      date: data.date,
      canView: data?.canView || false
    }
  });
};

export const checkIsMaintenance = () => (dispatch) => {
  dispatch({
    type: types.CHECK_IS_MAINTENANCE
  });
};
