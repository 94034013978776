import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Icon } from 'components';

import { useSDK } from './sdk';

import style from './Scanner.module.scss';

const keepCameraOn = false;

const Scanner = ({ onScan, closeScanner }) => {
  const host = useRef(null);
  const { loaded, sdk } = useSDK();

  const closeScannerHandler = () => {
    closeScanner && closeScanner();
    sdk.enableCamera(false);
  };

  const shouldKeepCameraOn = useCallback(async () => {
    if (!keepCameraOn) {
      await sdk.enableCamera(false);
    }
  }, [sdk, keepCameraOn]);

  const onScanHandler = useMemo(
    () => ({
      didScan: async (_, session) => {
        if (session.newlyRecognizedBarcode) {
          await sdk.enableScanning(false);
          await shouldKeepCameraOn();
          onScan(session.newlyRecognizedBarcode._data);
          closeScannerHandler();
        }
      }
    }),
    [sdk, shouldKeepCameraOn]
  );

  useEffect(() => {
    async function onMount() {
      if (loaded && host.current) {
        sdk.connectToElement(host.current);
        await sdk.enableCamera(true);
        await sdk.enableScanning(true);
        sdk.addBarcodeCaptureListener(onScanHandler);
      }
    }

    void onMount();
    return () => {
      if (loaded) {
        sdk.removeBarcodeCaptureListener(onScanHandler);
        sdk.detachFromElement();
      }
    };
  }, [loaded, sdk, onScanHandler]);

  return (
    <div className={style.scannerContainer}>
      <div className={style.header}>
        <h3 className={style.title}>Skanuj kod kreskowy</h3>
        <button
          className={style.close}
          onClick={closeScannerHandler}
        >
          <Icon
            name='close'
            size='20'
          />
        </button>
      </div>
      <div
        ref={host}
        className={style.scanner}
      />
    </div>
  );
};

export default Scanner;
