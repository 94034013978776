import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Modal, PageHeader, PaginationNav, SeoHelmet, useSearchParam } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { PriceListsApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import { handleNumeralWords } from 'src/utils/helpers';
import query from 'src/utils/query';

import AssignClient from './components/AssignClient';
import AssignedClients from './components/AssignedClients';
import ProductsPriceTable from './components/ProductsPriceTable';

import style from './PriceListView.module.scss';

const PriceListView = (props) => {
  const { id } = useParams();
  const params = query(props);

  const [isAssignClientModalVisible, setIsAssignClientModalVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [priceListData, setPriceListData] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const currentPage = useSearchParam(params, 'page');
  const querySearch = useSearchParam(params, 'search');

  const getData = async () => {
    const queryParams = {
      fpl_id: id,
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch
    };

    try {
      const { data } = await PriceListsApi.getPrices(queryParams);
      setPriceListData(data.data || null);
      setPageQty(data.last_page);
      setTotalProducts(data.total);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const getDataWithLoading = async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    };

    getDataWithLoading();
  }, [currentPage, perPage, querySearch]);

  return (
    <>
      <SeoHelmet title={pages.priceListView.title} />
      <PageHeader
        name={`Cennik: ${priceListData?.price_list_name || ''}`}
        metaData={{
          author: priceListData?.wholesaler_name || 'Brak nazwy hurtowni'
        }}
        text={`${totalProducts} ${handleNumeralWords(['produkt', 'produkty', 'produktów'], totalProducts || 0)}`}
        isLoading={isLoading}
        className={style.title}
        breakpointBelowTablet
      >
        <Button
          label={'Przypisz klienta'}
          onClick={() => setIsAssignClientModalVisible(true)}
          className={style.button}
        />
      </PageHeader>

      <div className={style.container}>
        <AssignedClients
          priceListId={+id}
          clients={priceListData?.clients || []}
          refreshData={getData}
        />

        <div className={style.products}>
          <ProductsPriceTable
            priceListId={+id}
            wholesalerId={priceListData?.wholesaler_id}
            params={params}
            products={priceListData?.products || []}
            refreshData={getData}
            isLoading={isLoading}
          />
          <PaginationNav
            params={params}
            pagesQty={pageQty}
            setPerPage={setPerPage}
            defaultQty={perPage}
            hidePadding
          />
        </div>
      </div>

      <Modal
        visible={isAssignClientModalVisible}
        onClose={() => setIsAssignClientModalVisible(false)}
        title={'Przypisz klienta'}
      >
        <AssignClient
          priceListId={+id}
          wholesalerId={priceListData?.wholesaler_id}
          onSubmit={() => {
            setIsAssignClientModalVisible(false);
            getData();
          }}
        />
      </Modal>
    </>
  );
};

export default PriceListView;
