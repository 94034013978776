import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAuthUser, useIsAdmin } from 'components';

import { checkIsMaintenance, setMaintenance } from 'src/features/Maintenance/actions';

const MaintenanceListener = () => {
  const dispatch = useDispatch();
  const user = useAuthUser();
  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (!user) return;

    dispatch(
      setMaintenance({
        date: user.maintenance,
        canView: isAdmin
      })
    );

    const interval = setInterval(() => dispatch(checkIsMaintenance()), 60 * 1000);

    return () => clearInterval(interval);
  }, [user]);

  return null;
};

export default MaintenanceListener;
