import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import { changeCompaniesVersion } from '../../actions';

import style from './BillingPeriod.module.scss';

const BillingPeriod = ({ company, closeModal }) => {
  const dispatch = useDispatch();
  const validator = useValidator();

  const [isLoading, setIsLoading] = useState(false);
  const [billingDate, setBillingDate] = useState(company.billing_period || '');

  const handleSubmit = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const isHeadquarter = !company.company_id;
    const payload = {
      company_id: isHeadquarter ? company.id : company.company_id,
      date: billingDate
    };

    try {
      setIsLoading(true);
      await AdminApi.updateBillingPeriod(payload);
      dispatch(changeCompaniesVersion());
      notifyCommon(['Zapisano okres rozliczeniowy.']);
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <Input
        label={'Data rozliczenia'}
        type={'date'}
        value={billingDate}
        onChange={(e) => setBillingDate(e.target.value)}
        validator={validator}
      />

      <Button
        className={style.button}
        label='Zapisz datę'
        onClick={handleSubmit}
        disabled={isLoading}
      />
    </div>
  );
};

export default BillingPeriod;
