import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  BarcodeCapture,
  barcodeCaptureLoader,
  BarcodeCaptureOverlay,
  BarcodeCaptureSettings,
  Symbology
} from '@scandit/web-datacapture-barcode';
import {
  Camera,
  CameraSwitchControl,
  configure,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState
} from '@scandit/web-datacapture-core';

export const createSDKFacade = () => {
  let context;
  let view;
  let settings;
  let barcodeCapture;
  let overlay;
  let host;
  let cameraSwitchControl;
  let camera;

  function createHostElementIfNeeded() {
    if (!host) {
      // workaround for React
      host = document.createElement('div');
      host.style.display = 'none';
      host.style.width = '100%';
      host.style.height = '100%';
      document.body.append(host);
    }
    return host;
  }

  return {
    async initialize() {
      try {
        await configure({
          licenseKey: import.meta.env.VITE_APP_SCANDIT_LICENSE_KEY,
          libraryLocation: '/lib/barcode-scanner/',
          moduleLoaders: [barcodeCaptureLoader()]
        });
      } catch (error) {
        console.error(error);
      }

      context = await DataCaptureContext.create();
      settings = new BarcodeCaptureSettings();
      settings.enableSymbologies([
        Symbology.EAN13UPCA,
        Symbology.EAN8,
        Symbology.UPCE,
        Symbology.QR,
        Symbology.DataMatrix,
        Symbology.Code39,
        Symbology.Code128,
        Symbology.InterleavedTwoOfFive
      ]);

      view = await DataCaptureView.forContext(context);
      view.connectToElement(createHostElementIfNeeded());

      cameraSwitchControl = new CameraSwitchControl();
      view.addControl(cameraSwitchControl);

      barcodeCapture = await BarcodeCapture.forContext(context, settings);
      await barcodeCapture.setEnabled(false);

      overlay = await BarcodeCaptureOverlay.withBarcodeCaptureForView(barcodeCapture, view);
      await view.addOverlay(overlay);

      camera = Camera.default;
      await camera.applySettings(BarcodeCapture.recommendedCameraSettings);
      await context.setFrameSource(camera);
    },
    async cleanup() {
      await context?.frameSource?.switchToDesiredState(FrameSourceState.Off);
      await context?.dispose();
      await context?.removeAllModes();
      if (overlay) {
        await overlay.setViewfinder(null);
        await view?.removeOverlay(overlay);
      }
      if (cameraSwitchControl) {
        view?.removeControl(cameraSwitchControl);
        cameraSwitchControl = undefined;
      }
      view?.detachFromElement();
      barcodeCapture = undefined;
      context = undefined;
      view = undefined;
      settings = undefined;
      camera = undefined;
      host?.remove();
      host = undefined;
    },
    connectToElement(element) {
      host = createHostElementIfNeeded();
      host.style.display = 'block';
      element.append(host);
    },
    detachFromElement() {
      if (host) {
        host.style.display = 'none';
        document.body.append(host);
      }
    },
    async enableCamera(enabled) {
      if (context?.frameSource) {
        await context.frameSource.switchToDesiredState(enabled ? FrameSourceState.On : FrameSourceState.Off);
      }
    },
    async enableScanning(enabled) {
      await barcodeCapture?.setEnabled(enabled);
    },
    async enableSymbology(symbology, enabled) {
      !!settings && settings.enableSymbology(symbology, enabled);
      !!settings && (await barcodeCapture?.applySettings(settings));
    },
    addBarcodeCaptureListener(listener) {
      barcodeCapture?.addListener(listener);
    },
    removeBarcodeCaptureListener(listener) {
      barcodeCapture?.removeListener(listener);
    },
    getEnabledSymbologies() {
      return settings ? settings.enabledSymbologies : [];
    }
  };
};

export const SDKContext = createContext({
  loaded: false,
  loading: false,
  sdk: null
});

const SDKProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const sdk = useMemo(() => createSDKFacade(), []);

  const providerValue = useMemo(() => ({ loading, loaded, sdk }), [loading, loaded, sdk]);

  useEffect(() => {
    const start = async () => {
      setLoading(true);
      await sdk.initialize();
      setLoading(false);
      setLoaded(true);
    };
    void start();
    return () => {
      void sdk.cleanup();
    };
  }, [sdk]);

  return <SDKContext.Provider value={providerValue}>{children}</SDKContext.Provider>;
};

export default SDKProvider;

export const useSDK = () => {
  const value = useContext(SDKContext);
  if (value.sdk === null) {
    throw new Error('Sdk facade is null. Did you forget to wrap the component with SDKProvider?');
  }
  return value;
};
