import React, { useEffect, useState } from 'react';

import { AsyncSelect, Button, QtySelector, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { PriceListsApi, ProductsApi } from 'src/api';

import style from './AddProductPrice.module.scss';

const AddProductPrice = ({ closeModal, onSubmit, priceListId, wholesalerId }) => {
  const validator = useValidator();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [price, setPrice] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const payload = {
      fpl_id: priceListId,
      product_id: selectedProduct.value,
      price: price
    };

    try {
      setIsLoading(true);
      await PriceListsApi.addOrUpdatePrice(payload);
      notifyCommon([`Dodano cenę dla produktu ${selectedProduct.label}`]);

      onSubmit();
      setSelectedProduct(null);
      setPrice(0);
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <div className={style.inputs}>
        <AsyncSelect
          apiCallback={ProductsApi.getProducts}
          onChange={setSelectedProduct}
          value={selectedProduct}
          placeholder={'Produkt'}
          id={'select-product'}
          validator={validator}
          queryParams={{ wholesaler_id: wholesalerId }}
          rule={'required'}
          labelKey={'name'}
          label={'Produkt'}
          valueKey={'id'}
          productSearch
          isClearable
        />
        <QtySelector
          state={price}
          setState={setPrice}
          suffix={'zł'}
          hideArrows
          width={'100%'}
          id={'priceInput'}
          wrapperStyle={style.priceInput}
          label={'Cena'}
          priceInput
          validator={validator}
          rule={'required|min_price'}
        />
      </div>
      <Button
        className={style.button}
        label={'Dodaj cenę'}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default AddProductPrice;
