import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import style from './PublicFooter.module.scss';

const PublicFooter = ({ white, className }) => {
  return (
    <footer className={classNames(style.container, className, { [style.white]: white })}>
      <div className={style.wrapper}>
        <span>
          app.avalio.io
          <span className={style.conjunction}> -</span>
        </span>
        <Link
          to={{ pathname: 'https://avalio.io/privacy-policy' }}
          className={style.link}
          target='_blank'
        >
          Polityka prywatności
        </Link>
        <span className={style.conjunction}> | </span>
        <Link
          to={{ pathname: 'https://avalio.io/statute' }}
          className={style.link}
          target='_blank'
        >
          Regulamin platformy
        </Link>
        <span className={style.conjunction}> | </span>
        <Link
          to={{ pathname: 'https://avalio.io' }}
          className={style.link}
          target='_blank'
        >
          FAQ
        </Link>
      </div>
      <div className={style.wrapper}>Copyright &copy; {new Date().getFullYear()} by Avalio</div>
    </footer>
  );
};

export default PublicFooter;
