import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import logoPlaceholder from 'assets/img/no-image.png';
import { Card, CompanyDetails, InfoWidget, Menu, Modal, Tag, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { changeCompaniesVersion } from 'src/features/CompaniesList/actions';
import { toDateNumber } from 'src/utils/dateTime';
import { imageErrorHandler } from 'src/utils/helpers';

import * as types from '../../actions/types';
import Addresses from '../Addresses';
import BillingPeriod from '../BillingPeriod';
import CompanySettings from '../CompanySettings';
import MoveCompany from '../MoveCompany';
import Personnel from '../Personnel';

import style from './CompanyThumbnail.module.scss';
import shared from 'styles/Shared.module.scss';

const CompanyThumbnail = ({ company, index }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [contentType, setContentType] = useState('');
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const searchOwnerHandler = () => history.push(`/users?search=${company.email}`);

  const handleRefresh = () => {
    dispatch({ type: types.CHANGE_COMPANIES_VERSION });
  };

  const openModalHandler = (content = '') => {
    setContentType(content);
    setIsVisible(true);
  };

  const modalContent = useMemo(() => {
    switch (contentType) {
      case 'ADDRESSES':
        return {
          content: <Addresses companyId={company.id} />,
          title: `Adresy dostaw oddziału: ${company.name}`
        };
      case 'PERSONNEL':
        return {
          content: <Personnel companyId={company.id} />,
          title: `Personel oddziału: ${company.name}`
        };
      case 'BILLING':
        return {
          content: (
            <BillingPeriod
              company={company}
              closeModal={() => setIsVisible(false)}
            />
          ),
          title: 'Okres rozliczeniowy firmy'
        };
      case 'SETTINGS':
        return {
          content: <CompanySettings company={company} />,
          title: 'Ustawienia organizacji'
        };
      case 'MOVE':
        return {
          content: (
            <MoveCompany
              companyId={company.id}
              companyName={company.name}
              closeModal={() => setIsVisible(false)}
            />
          ),
          title: `Przenieś oddział ${company.name}`
        };
      default:
        return {
          content: null,
          title: ''
        };
    }
  }, [contentType, company]);

  const deleteCompany = async () => {
    try {
      await AdminApi.deleteCompany(company.id);
      notifyCommon([`Firma "${company.name}" została dezaktywowana.`]);
      dispatch(changeCompaniesVersion());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const activateCompany = async () => {
    try {
      await AdminApi.activateCompany(company.id);
      notifyCommon([`Firma "${company.name}" została aktywowana.`]);
      dispatch(changeCompaniesVersion());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteCompanyHandler = () =>
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz dezaktywować Firmę ${company.name}?`,
      handleConfirm: deleteCompany,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });

  const activateCompanyHandler = () =>
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz aktywować Firmę ${company.name}?`,
      handleConfirm: activateCompany,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });

  const actions = [
    {
      title: 'Personel',
      icon: 'list',
      action: () => openModalHandler('PERSONNEL')
    },
    {
      title: 'Adresy dostaw',
      icon: 'home',
      action: () => openModalHandler('ADDRESSES')
    },
    {
      title: 'Przenieś oddział',
      icon: 'move',
      action: () => openModalHandler('MOVE')
    },
    {
      title: 'Ustaw okres rozliczeniowy',
      icon: 'calendar',
      action: () => openModalHandler('BILLING')
    },
    {
      title: 'Ustawienia organizacji',
      icon: 'setting',
      color: 'blue',
      action: () => openModalHandler('SETTINGS')
    },
    {
      title: 'Aktywuj firmę',
      icon: 'check',
      color: 'blue',
      action: activateCompanyHandler,
      roles: company?.deleted_at ? ['admin'] : []
    },
    {
      title: 'Dezaktywuj firmę',
      icon: 'trash',
      color: 'red',
      action: deleteCompanyHandler,
      roles: company?.deleted_at ? [] : ['admin']
    }
  ];

  return (
    <Card
      key={company.id}
      withShadow
      red={!!company.deleted_at}
    >
      {renderedModalConfirm}
      <Menu actions={actions} />
      <div className={style.titleWrapper}>
        <img
          src={company?.logo_small || logoPlaceholder}
          className={classNames(shared.companyLogo, shared.large)}
          alt='Company logo'
          onError={imageErrorHandler}
        />
        <h2 className={style.companyTitle}>{company.name}</h2>
        {!company.company_id && (
          <Tag
            value={'HQ'}
            title={'Główny oddział organizacji'}
            color={'blue'}
          />
        )}
        {!!company.user_name && (
          <Tag
            onClick={searchOwnerHandler}
            value={'Właściciel: ' + company.user_name}
          />
        )}
        {!!company.billing_period && (
          <Tooltip
            placement='top'
            title={`Data rozliczeniowa firmy: ${toDateNumber(company.billing_period)}`}
          >
            <Tag
              value={`Rozliczenie: ${toDateNumber(company.billing_period)}`}
              color={new Date(company.billing_period) < new Date() ? 'red' : 'yellow'}
            />
          </Tooltip>
        )}
        {!!company.deleted_at && (
          <InfoWidget place={'right'}>
            <p>Data dezaktywacji: {toDateNumber(company.deleted_at)}</p>
          </InfoWidget>
        )}
      </div>
      <CompanyDetails
        companyManagement
        details={company}
        renderChildren
        index={index}
        refreshCb={handleRefresh}
      />
      <Modal
        visible={isVisible}
        onClose={() => setIsVisible(false)}
        title={modalContent.title}
      >
        {modalContent.content}
      </Modal>
    </Card>
  );
};

export default CompanyThumbnail;
