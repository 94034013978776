import React from 'react';
import classNames from 'classnames';

import style from './Tag.module.scss';

const Tag = ({
  value,
  green = false,
  yellow = false,
  red = false,
  blue = false,
  className,
  color,
  title,
  icon,
  withWarning,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        style.container,
        {
          [style[color]]: !!color,
          [style.hasIcon]: !!icon
        },
        className
      )}
      {...rest}
      title={title ? title : value}
    >
      {icon && <span className={style.icon}>{icon}</span>}
      <span>{value}</span>
    </div>
  );
};

export default Tag;
