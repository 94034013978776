import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { PaginationNav, PriceChangeDifference, ProductThumbnailImage, RelativeTime } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';
import { notifyApiError } from 'components/layout/Toasts';

import { PriceListsApi } from 'src/api';
import { calculateDifference, getFormattedAmount } from 'src/utils/helpers';

import style from './ProductPriceHistory.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductPriceHistory = ({ product, priceListId }) => {
  const { thumbnail, image, product_name, producer_name, product_id } = product;

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [pageQty, setPageQty] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getPriceHistory = async () => {
    const query = {
      fpl_id: priceListId,
      product_id: product_id,
      page: currentPage || 1,
      perPage: perPage
    };
    try {
      setIsLoading(true);
      const { data } = await PriceListsApi.getPriceHistory(query);
      setData(data.data);
      setPageQty(data.last_page);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPriceHistory();
  }, [priceListId, product_id]);

  return (
    <div className={style.container}>
      <div className={style.product}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={product_name}
          id={product_id}
        />
        <span>{product_name}</span>
      </div>

      <div className={style.table}>
        <div className={classNames(shared.tableHeader, style.tableHeader)}>
          <p>Poprzednia cena</p>
          <p>Aktualna cena</p>
          <p>Różnica</p>
          <p>Data zmiany</p>
        </div>

        {isLoading && <ProductPlaceholder quantity={4} />}
        {!isLoading && data.length === 0 && <p className={style.emptyState}>Brak danych</p>}
        {!isLoading &&
          data.map((item) => {
            const difference = calculateDifference(item.value.old_price, item.value.new_price);

            return (
              <div
                className={style.item}
                key={item.id}
              >
                <div>{item.value.old_price ? `${getFormattedAmount(item.value.old_price)}` : '-'}</div>
                <div className={style.priceContainer}>
                  {item.value.new_price ? `${getFormattedAmount(item.value.new_price)}` : '-'}
                </div>
                <div>
                  {item.value.old_price ? (
                    <PriceChangeDifference
                      difference={{ percentage: difference.percentageDifference, numeric: difference.numericDifference }}
                    />
                  ) : (
                    '-'
                  )}
                </div>
                <div>
                  <RelativeTime date={item.created_at} />
                </div>
              </div>
            );
          })}
      </div>

      <PaginationNav
        setPage={setCurrentPage}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
        page={currentPage}
        className={style.pagination}
        hideSelect
      />
    </div>
  );
};

export default ProductPriceHistory;
