import React, { useMemo, useState } from 'react';

import { Menu, Modal, useIsAdmin } from 'components';

import ChangeOrderStatus from '../ChangeOrderStatus';

import style from './OrderAdminMenu.module.scss';

const OrderAdminMenu = ({ order }) => {
  const isAdmin = useIsAdmin();

  const [modalTypeOpen, setModalTypeOpen] = useState(null);

  const openModalHandler = (type = '') => {
    setModalTypeOpen(type);
  };

  const modalContent = useMemo(() => {
    switch (modalTypeOpen) {
      case 'ORDER_STATUS':
        return {
          content: (
            <ChangeOrderStatus
              order={order}
              closeModal={() => setModalTypeOpen(null)}
            />
          ),
          title: 'Zmień status zamówienia'
        };
      default:
        return {
          content: null,
          title: ''
        };
    }
  }, [modalTypeOpen, order]);

  const actions = [
    {
      title: 'Status zamówienia',
      icon: 'repeat',
      action: () => openModalHandler('ORDER_STATUS')
    }
  ];

  if (!isAdmin) return null;
  return (
    <>
      <Menu
        actions={actions}
        className={style.menu}
      />

      <Modal
        visible={modalContent.content !== null}
        onClose={() => setModalTypeOpen(null)}
        title={modalContent.title}
      >
        {modalContent.content}
      </Modal>
    </>
  );
};

export default OrderAdminMenu;
