import React, { useState } from 'react';

import { Button, FileDropzone, Modal, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { PriceListsApi } from 'src/api';

import style from './ImportPriceListPrices.module.scss';

const ImportPriceListPrices = ({ priceListId, buttonProps, onSubmit }) => {
  const validator = useValidator();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const formData = new FormData();
    formData.append('fpl_id', priceListId);
    formData.append('prices', file);

    try {
      setIsLoading(true);
      await PriceListsApi.importPrices(formData);
      notifyCommon(['Ceny zostały zaimportowane.']);
      validator.hideMessages();
      setFile(null);
      onSubmit && onSubmit();
      setIsOpen(false);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        label={'Importuj ceny'}
        onClick={() => setIsOpen(true)}
        {...buttonProps}
      />
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Importuj ceny z pliku'}
      >
        <form
          className={style.container}
          onSubmit={submitHandler}
        >
          <p>Wybierz plik i zapisz, aby zaktualizować cennik.</p>

          <FileDropzone
            label={'Plik z cenami'}
            onChange={setFile}
            value={file}
            accept={'.xlsx, .xls, .csv'}
            validator={validator}
            rule={'required'}
          />
          <Button
            label={'Zaimportuj'}
            type={'submit'}
            className={style.button}
            isLoading={isLoading}
          />
        </form>
      </Modal>
    </>
  );
};

export default ImportPriceListPrices;
