import React from 'react';
import { useSelector } from 'react-redux';

const useIsMaintenance = () => {
  const { isMaintenance, canView } = useSelector((state) => state.maintenance);
  const { isLoading } = useSelector((state) => state.auth);

  return isMaintenance && !canView && !isLoading;
};

export default useIsMaintenance;
