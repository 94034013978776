import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { PriceListsApi } from 'src/api';

import { changePriceListsVersion } from '../../../PriceLists/actions';

import style from './EditPriceList.module.scss';

const EditPriceList = ({ closeModal, priceListId, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [priceListName, setPriceListName] = useState(data.name || '');
  const validator = useValidator();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      fpl_id: priceListId,
      name: priceListName
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await PriceListsApi.editList(payload);
      notifyCommon([`Cennik ${priceListName} został zaktualizowany!`]);
      dispatch(changePriceListsVersion());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <div className={style.inputs}>
        <Input
          id={'price-list-name'}
          name={'price-list-name'}
          label={'Nazwa cennika'}
          placeholder={'Cennik hurtowy'}
          value={priceListName}
          onChange={(e) => setPriceListName(e.target.value)}
          validator={validator}
          dispatch={isLoading}
          rule={'required'}
        />
      </div>
      <Button
        className={style.button}
        label={'Zapisz zmiany'}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default EditPriceList;
