import React from 'react';

import { FilterByDateRange } from 'components';

import style from './PriceListsFiltering.module.scss';

const PriceListsFiltering = ({ params }) => {
  return (
    <div className={style.filtering}>
      <FilterByDateRange params={params} />
    </div>
  );
};

export default PriceListsFiltering;
