import React, { useState } from 'react';
import classNames from 'classnames';

import { Button, Modal, Searchbar } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import addIcon from 'src/assets/icons/add.svg';

import AddProductPrice from '../AddProductPrice';
import ExportPriceListPrices from '../ExportPriceListPrices';
import ImportPriceListPrices from '../ImportPriceListPrices';
import ProductPriceThumbnail from '../ProductPriceThumbnail';

import style from './ProductsPriceTable.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductsPriceTable = ({ priceListId, wholesalerId, params, products, isLoading, refreshData }) => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  return (
    <section className={style.container}>
      <div className={style.header}>
        <Searchbar
          params={params}
          wrapperStyle={style.searchbar}
        />
        <ImportPriceListPrices
          priceListId={priceListId}
          buttonProps={{ gray: true }}
          onSubmit={refreshData}
        />
        <ExportPriceListPrices
          priceListId={priceListId}
          buttonProps={{ gray: true }}
        />
        <Button
          label={'Dodaj pozycję'}
          icon={addIcon}
          onClick={() => setIsAddModalVisible(true)}
          className={style.button}
        />
      </div>

      <div className={classNames(shared.tableWrapper, style.products)}>
        <div className={classNames(shared.tableHeader, style.tableHeader)}>
          <p>Nazwa</p>
          <p>Producent</p>
          <p>Cena za szt.</p>
        </div>
        {products?.length ? (
          products?.map((product, i) => (
            <ProductPriceThumbnail
              key={`product-price-${product.product_id}-${i}`}
              productPrice={product}
              priceListId={priceListId}
              refreshData={refreshData}
            />
          ))
        ) : (
          <div className={shared.tableCategory}>
            {isLoading && <ProductPlaceholder quantity={4} />}
            {!isLoading && products.length === 0 && <p className={style.emptyState}>Brak produktów w cenniku</p>}
          </div>
        )}
      </div>

      <Modal
        visible={isAddModalVisible}
        onClose={() => setIsAddModalVisible(false)}
        title={'Dodaj pozycję do cennika'}
      >
        <AddProductPrice
          priceListId={priceListId}
          wholesalerId={wholesalerId}
          closeModal={() => setIsAddModalVisible(false)}
          onSubmit={refreshData}
        />
      </Modal>
    </section>
  );
};

export default ProductsPriceTable;
