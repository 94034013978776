import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Select, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { orderStatuses } from 'src/constants/enums';
import { getOrderStatusTag } from 'src/utils/helpers';

import { refreshOrder } from '../../actions';

import style from './ChangeOrderStatus.module.scss';

const getStatusLabel = (status) => getOrderStatusTag({ status, returnData: true, wholeOrderStatus: true }).title;
const statusToOption = (status) => ({ label: getStatusLabel(status), value: status });

const options = [
  statusToOption(orderStatuses.pending),
  statusToOption(orderStatuses.completed),
  statusToOption(orderStatuses.cancelled)
];

const ChangeOrderStatus = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const validator = useValidator();

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(order?.status || null);

  const refreshDataCallback = () => dispatch(refreshOrder());

  const handleSubmit = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const payload = {
      order_id: order.id,
      status: status
    };

    try {
      setIsLoading(true);
      await AdminApi.changeOrderStatus(payload);
      refreshDataCallback();
      notifyCommon(['Status zamówienia został zmieniony.']);
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <Select
        placeholder='Wybierz status...'
        options={options}
        value={status ? statusToOption(status) : null}
        onChange={(option) => setStatus(option ? option.value : null)}
        isSearchable={false}
        validator={validator}
        rule='required'
      />

      <Button
        className={style.button}
        label='Zmień status'
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={!status || status === order?.status}
      />
    </div>
  );
};

export default ChangeOrderStatus;
