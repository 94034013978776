import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, useValidator } from 'components';
import { Select } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { PriceListsApi } from 'src/api';

import { changePriceListsVersion } from '../../actions';

import style from './CreatePriceList.module.scss';

const CreatePriceList = ({ closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWholesaler, setSelectedWholesaler] = useState(null);
  const [wholesalerOptions, setWholesalerOptions] = useState([]);
  const [priceListName, setPriceListName] = useState('');
  const validator = useValidator();
  const dispatch = useDispatch();

  const getWholesalers = async () => {
    const query = {
      page: 1,
      perPage: 10000
    };
    try {
      const { data } = await PriceListsApi.getWholesalers(query);
      const options = data?.data.map((wholesaler) => ({
        value: wholesaler.id,
        label: wholesaler.name
      }));
      setWholesalerOptions(options);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    getWholesalers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      wholesaler_id: selectedWholesaler.value,
      name: priceListName
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await PriceListsApi.addList(payload);
      notifyCommon([`Cennik ${priceListName} został utworzony!`]);
      dispatch(changePriceListsVersion());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <div className={style.inputs}>
        <Input
          id={'price-list-name'}
          name={'price-list-name'}
          label={'Nazwa cennika'}
          placeholder={'Cennik hurtowy'}
          value={priceListName}
          onChange={(e) => setPriceListName(e.target.value)}
          validator={validator}
          dispatch={isLoading}
          rule={'required'}
        />
        <Select
          options={wholesalerOptions}
          value={selectedWholesaler}
          onChange={setSelectedWholesaler}
          isClearable={false}
          isSearchable
          isDisabled={wholesalerOptions.length < 2 || isLoading}
          placeholder={'Wybierz hurtownię...'}
        />
      </div>
      <Button
        className={style.button}
        label={'Utwórz cennik'}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default CreatePriceList;
