import React, { useState } from 'react';

import { Button, Select, useIsAdmin } from 'components';
import ChangeMenu from 'components/layout/changeMenu';
import ProductStatusExtended from 'components/layout/ProductStatusExtended';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { orderStatuses } from 'src/constants/enums';
import { getOrderStatusTag } from 'src/utils/helpers';

import style from './OrderProductStatus.module.scss';

const getStatusLabel = (status) => getOrderStatusTag({ status, returnData: true, wholeOrderStatus: false })?.title || '-';
const statusToOption = (status) => ({ label: getStatusLabel(status), value: status });

const options = [
  statusToOption(orderStatuses.pending),
  statusToOption(orderStatuses.completed),
  statusToOption(orderStatuses.cancelled)
];

const OrderProductStatus = ({ className, product, refreshData, allowChange }) => {
  const isAdmin = useIsAdmin();

  const [isActive, setIsActive] = useState(false);
  const [status, setStatus] = useState(product.status || null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    if (!isAdmin || !status) return;

    const payload = {
      order_product_id: product.id,
      status
    };

    try {
      setIsLoading(true);
      await AdminApi.changeOrderProductStatus(payload);
      refreshData && refreshData();
      setIsActive(false);
      notifyCommon(['Status produktu w zamówieniu został zmieniony.']);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!allowChange || !isAdmin)
    return (
      <ProductStatusExtended
        product={product}
        className={className}
      />
    );

  return (
    <ChangeMenu
      isActive={isActive}
      setIsActive={setIsActive}
      element={
        // it requires extra div wrapper for proper click handling
        <div className={className}>
          <ProductStatusExtended product={product} />
        </div>
      }
    >
      <Select
        placeholder='Wybierz status...'
        options={options}
        value={status ? statusToOption(status) : null}
        onChange={(option) => setStatus(option ? option.value : null)}
        isSearchable={false}
      />
      <Button
        label={'Zapisz'}
        onClick={handleSave}
        className={style.save}
        isLoading={isLoading}
        disabled={!status}
      />
    </ChangeMenu>
  );
};

export default OrderProductStatus;
