import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { Button, Input, ProfileSection as Section, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { refreshUserData } from 'src/features/onboarding/actions';

import style from './MaintenanceSettings.module.scss';

const MaintenanceSettings = () => {
  const { isMaintenance, maintenanceStartDate } = useSelector((state) => state.maintenance);
  const dispatch = useDispatch();
  const validator = useValidator();

  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState('');

  const handleSubmit = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const payload = {
      date: !maintenanceStartDate ? dayjs(startDate).tz('Europe/Warsaw').format('YYYY-MM-DDTHH:mm:ss') : null
    };

    try {
      setIsLoading(true);
      await AdminApi.setMaintenance(payload);
      dispatch(refreshUserData());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (maintenanceStartDate) {
      const localDate = dayjs(maintenanceStartDate).format('YYYY-MM-DDTHH:mm');
      setStartDate(localDate);
    } else {
      setStartDate('');
    }
  }, [maintenanceStartDate]);

  const status = (
    <b
      className={classNames(style.status, {
        [style.off]: !isMaintenance,
        [style.planned]: !isMaintenance && maintenanceStartDate
      })}
    >
      {isMaintenance ? 'Włączony' : 'Wyłączony'} {!isMaintenance && maintenanceStartDate ? '(zaplanowany)' : ''}
    </b>
  );

  return (
    <div>
      <Section title={'Ustawienia trybu serwisowego'}>
        <div className={style.container}>
          <p>Status: {status}</p>

          <Input
            label={'Data rozpoczęcia serwisu'}
            type={'datetime-local'}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            validator={validator}
            rule={!maintenanceStartDate ? 'required' : undefined}
          />

          <Button
            className={style.button}
            label={maintenanceStartDate ? 'Anuluj serwis' : 'Zaplanuj serwis'}
            onClick={handleSubmit}
            gray={maintenanceStartDate}
            isLoading={isLoading}
          />
        </div>
      </Section>
    </div>
  );
};

export default MaintenanceSettings;
