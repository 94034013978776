import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Button,
  Modal,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  StickyPageHeader,
  useIsMobile,
  useRequestAbortController,
  useSearchParam
} from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { PriceListsApi } from 'src/api';
import addIcon from 'src/assets/icons/add.svg';
import pages from 'src/dictionaries/pages.json';
import query from 'src/utils/query';

import CreatePriceList from './components/CreatePriceList';
import PriceListsFiltering from './components/PriceListsFiltering';
import PriceListThumbnail from './components/PriceListThumbnail';

import style from './PriceLists.module.scss';

const PriceLists = (props) => {
  const params = query(props);
  const isMobile = useIsMobile();
  const priceListsVersion = useSelector((state) => state.priceLists.version);

  const [itemsQty, setItemsQty] = useState();
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const sortBy = useSearchParam(params, 'sort_by');
  const currentPage = useSearchParam(params, 'page');
  const querySearch = useSearchParam(params, 'search');
  const sortOrder = useSearchParam(params, 'sort_order');
  const dateFrom = useSearchParam(params, 'startDate');
  const dateTo = useSearchParam(params, 'endDate');

  useEffect(() => {
    getData();
  }, [currentPage, querySearch, perPage, priceListsVersion, sortBy, sortOrder, dateFrom, dateTo]);

  const getData = async () => {
    let error;
    const queryProps = {
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: sortOrder }),
      ...(dateFrom && { date_from: moment(dateFrom).format('Y-MM-DD') }),
      ...(dateTo && { date_to: moment(dateTo).format('Y-MM-DD') })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await PriceListsApi.getPriceLists(queryProps, signal);
      setPageData(data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
    }
  };

  return (
    <>
      <SeoHelmet title={pages.priceLists.title} />
      <StickyPageHeader
        name={pages.priceLists.title}
        belowContent={<PriceListsFiltering params={params} />}
      >
        <Searchbar
          params={params}
          wrapperStyle={style.searchbar}
        />
        <Button
          label={'Dodaj cennik'}
          icon={addIcon}
          onClick={() => setIsAddModalVisible(true)}
          className={style.button}
        />
      </StickyPageHeader>
      <div className={style.container}>
        <div className={style.list}>
          {pageData.data?.map((item) => (
            <PriceListThumbnail
              key={item.id}
              data={item}
            />
          ))}
        </div>
        {/* <UsersTable
          list={pageData}
          isLoading={isLoading}
          params={params}
        /> */}
        <PaginationNav
          params={params}
          pagesQty={pageQty}
          setPerPage={setPerPage}
          defaultQty={perPage}
        />

        <Modal
          visible={isAddModalVisible}
          onClose={() => setIsAddModalVisible(false)}
          title={'Utwórz cennik'}
        >
          <CreatePriceList closeModal={() => setIsAddModalVisible(false)} />
        </Modal>
      </div>
    </>
  );
};

export default PriceLists;
