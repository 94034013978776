import React from 'react';
import cn from 'classnames';

import { EmptyState, PublicFooter, PublicHeader } from 'components';

import style from './MaintenanceView.module.scss';
import shared from 'styles/Shared.module.scss';

const MaintenanceView = () => {
  return (
    <>
      <PublicHeader supplierForm />
      <div className={cn(shared.publicContainer, style.container)}>
        <EmptyState type={'maintenanceAvalio'} />
      </div>
      <PublicFooter className={style.footer} />
    </>
  );
};

export default MaintenanceView;
