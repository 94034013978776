import React from 'react';
import classNames from 'classnames';

import { Modal, ProductThumbnailImage, SaveButton } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { toDateNumber } from 'src/utils/dateTime';

import style from './ProductOcrInvoicesList.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductOcrInvoicesList = ({ title, visible, onClose, product }) => {
  const { product_id, thumbnail, name, image, producer, ocr_invoices = [] } = product;

  const downloadFile = (url, fileName, onlyFileView = false) => {
    const link = document.createElement('a');

    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    if (!onlyFileView) {
      link.setAttribute('download', fileName);
    }

    document.body.appendChild(link);
    link.click();
  };

  const downloadInvoice = async (fileName, onlyFileView = false) => {
    const params = { file_name: fileName };

    try {
      const { data, headers } = await AdminApi.downloadInvoice(params);
      const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
      downloadFile(url, fileName, onlyFileView);
    } catch (err) {
      notifyApiError(err);
    }
  };

  return (
    <Modal
      title={title || 'Lista faktur OCR produktu'}
      visible={visible}
      onClose={onClose}
    >
      <div className={style.container}>
        <div className={style.product}>
          <ProductThumbnailImage
            thumbnail={thumbnail}
            image={image}
            title={name}
            id={product_id}
          />
          <div className={style.wrapper}>
            <p className={style.text}>{name}</p>
            <p className={style.producer}>Producent: {producer}</p>
          </div>
        </div>

        <div className={style.table}>
          <div className={classNames(shared.tableHeader, style.header)}>
            <div className={style.invoiceNumber}>Numer faktury</div>
            <div className={style.invoiceSaleDate}>Data sprzedaży</div>
            <div></div>
            <div></div>
          </div>
          {ocr_invoices.length === 0 && <div className={style.empty}>Brak faktur OCR dla tego produktu</div>}
          {ocr_invoices.map((invoice, i) => (
            <div
              key={`ocr-invoice-${i}`}
              className={style.invoice}
            >
              <div className={style.invoiceNumber}>{invoice.invoice_number}</div>
              <div className={style.invoiceSaleDate}>{toDateNumber(invoice.sale_date) || '-'}</div>
              <SaveButton
                onClick={() => downloadInvoice(invoice.name)}
                type={'download'}
              />
              <SaveButton
                onClick={() => downloadInvoice(invoice.name, true)}
                type={'search'}
              />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ProductOcrInvoicesList;
