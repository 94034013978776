import dayjs from 'dayjs';

import * as types from '../actions/types';

export const initialState = {
  isMaintenance: false,
  maintenanceStartDate: null,
  canView: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MAINTENANCE: {
      const startDate = action.payload.date;
      const currentDate = dayjs().tz('Europe/Warsaw');

      return {
        ...state,
        isMaintenance: startDate ? currentDate.isAfter(startDate) : false,
        maintenanceStartDate: startDate,
        canView: action.payload.canView
      };
    }
    case types.CHECK_IS_MAINTENANCE: {
      const startDate = dayjs(state.maintenanceStartDate);
      const currentDate = dayjs().tz('Europe/Warsaw');

      return {
        ...state,
        isMaintenance: state.maintenanceStartDate ? currentDate.isAfter(startDate) : false
      };
    }
    default:
      return state;
  }
};
